
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
} from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Box, CircularProgress } from "@material-ui/core";
import ButtonWithLoading from "./ButtonWithLoading";
import { getTranslation } from "../heplers/translationHelper";
export const HtmlEditor = forwardRef(
	(
		{
			text,
			title,
			isLoading,
			isSubmitting,
			onChange,
			forms,
			tables,
			sections,
			disabled,
			onClickEvent,
		},
		ref
	) => {
		const editorRef = ref;
		const handleSubmit = () => {
			
			console.log("akjshgdkjasgdas",editorRef)
			if (editorRef?.current) {
				onChange(editorRef?.current?.getContent());
			}
		};
		var useDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

		function formMenu(callback, editor) {
			
			const items = [];
			forms.forEach((form) => {
				const subMenuItems = [];
				form.formFields.forEach((field) => {
					subMenuItems.push({
						type: "menuitem",
						text: field?.title,
						onAction: function () {
							var currentNode = editor.selection.getNode();
							var newNode = document.createElement("span");
								newNode.setAttribute("data-form-id",form.id);
								newNode.setAttribute("data-field-id",field.id);
								newNode.innerHTML=`[${form.title} | ${field?.title}]`;
								newNode.contentEditable=false
							if(currentNode.nodeName.toLowerCase()=="span"){
							  currentNode.parentNode.insertBefore(newNode, currentNode.nextSibling);
							}else{								
								currentNode.appendChild(newNode)
							}
						},
					});
				});
				items.push({
					type: "nestedmenuitem",
					text: form.title,
					getSubmenuItems: () => subMenuItems,
				});
			});
			callback(items);
		}

		const inserHtmltable = (table) => {
			let index = 0;
			let html = `<table style="width:100%" data-table-id = ${table?.id}>`;
			html += `<thead>`;
			html += `<tr>`;
			table?.tableColumns.map((row) => {
				html += `<th>${row?.title}</th>`;
			});
			html += `</tr>`;
			html += `</thead>`;
			html += `<tbody>`;
			html += `<tr>`;
			table?.tableColumns.map((row) => {
				html += `<td>${" Value " + (index + 1)}</td>`;
				index++;
			});
			html += `</tr>`;
			html += `<tr>`;

			table?.tableColumns.map((row) => {
				html += `<td>${" Value " + (index + 1)}</td>`;
				index++;
			});

			html += `</tr>`;
			html += `</tbody>`;

			html += `</table><br/>`;
			return html;
		};

		function tableMenu(callback, editor) {
			const items = [];
			tables.forEach((table) => {
				items.push({
					type: "menuitem",
					text: table.title,
					onAction: function () {
						editor.insertContent(inserHtmltable(table));
					},
				});
			});
			callback(items);
		}

		function sectionMenu(callback, editor) {
			const items = [];
			sections.forEach((section) => {
				items.push({
					type: "menuitem",
					text: section.title,
					onAction: function () {
						editor.insertContent(`<div style="background:#233044;color:white;display:flex; width:100%;
          margin:10px; font-size:20px; font-weight:bold; align-item:center;justify-content:center;height:85px;padding:15px"
          data-section-id="${section.id}"> ${section?.title}</div><br/>`);
					},
				});
			});
			callback(items);
		}

		function companyMenu(callback, editor) {
			const items = [];
			//
			const subMenuItems = [];

			const subMenuItemsBoard = [];

			const subMenuItemsManager = [];

			const subMenuItemShareholder = [];

			//***********************SUB MENUS*********************************
			// SuMenu  (Bascis)
			subMenuItems.push({
				type: "menuitem",
				text: "Company Name",
				onAction: function () {
					editor.insertContent(`[Company Name]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Group Name",
				onAction: function () {
					editor.insertContent(`[Company Group Name]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Country Name",
				onAction: function () {
					editor.insertContent(`[Company Country Name]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Investor Relation",
				onAction: function () {
					editor.insertContent(`[Company Investor Realtion]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Company Reference code",
				onAction: function () {
					editor.insertContent(`[Company ReferenceCode]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "RCS Number",
				onAction: function () {
					editor.insertContent(`[Company RCS Number]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Incorporation Date",
				onAction: function () {
					editor.insertContent(`[Company Incorporation Date]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Street",
				onAction: function () {
					editor.insertContent(`[Company Street]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "City",
				onAction: function () {
					editor.insertContent(`[Company City]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Zip",
				onAction: function () {
					editor.insertContent(`[Company Zip]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "RBE",
				onAction: function () {
					editor.insertContent(`[Company RBE]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Financial Year Ends",
				onAction: function () {
					editor.insertContent(`[Company Financial Year Ends]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Legal Form",
				onAction: function () {
					editor.insertContent(`[Company Legal Form]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "VAT",
				onAction: function () {
					editor.insertContent(`[Company Vat]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Liquidation Date",
				onAction: function () {
					editor.insertContent(`[Company Liquidation Date]`);
				},
			});
			subMenuItems.push({
				type: "menuitem",
				text: "Comments",
				onAction: function () {
					editor.insertContent(`[Company Comments]`);
				},
			});

			//***********************MAIN CATAGERY*********************************
			//(Basic)
			items.push({
				type: "nestedmenuitem",
				text: "Basics",
				getSubmenuItems: () => subMenuItems,
			});
			// (Board)
			items.push({
				type: "menuitem",
				text: "Board",
				onAction: function () {
					editor.insertContent(`<br/><div style="background:#233044;color:white;display:flex; width:100%;
          margin:10px; font-size:20px; font-weight:bold; align-item:center;justify-content:center;height:85px;padding:15px"
          data-company="board">Board Table</div><br/><br/>`);
				},
			});
			// (Manger)
			items.push({
				type: "menuitem",
				text: "Mangers",
				onAction: function () {
					editor.insertContent(`<br/><div style="background:#233044;color:white;display:flex; width:100%;
          margin:10px; font-size:20px; font-weight:bold; align-item:center;justify-content:center;height:85px;padding:15px"
          data-company="manager">Manger Table</div><br/><br/>`);
				},
			});
			// (Share holders)
			items.push({
				type: "menuitem",
				text: "Shareholders",
				onAction: function () {
					editor.insertContent(`<br/><br/><div style="background:#233044;color:white;display:flex; width:100%;
          margin:10px; font-size:20px; font-weight:bold; align-item:center;justify-content:center;height:85px;padding:15px"
          data-company="shareholder">Shareholder Tables</div><br/><br/>`);
				},
			});

			callback(items);
		}

		return isLoading == false ? (
			<>
				<Editor
					disabled={disabled}
					tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
					onInit={(evt, editor) =>
						editorRef ? (editorRef.current = editor) : null
					}
					initialValue={text}
					init={{
						forced_root_block : 'div',
						selector: "textarea#open-source-plugins",
						plugins:
							" preview  importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount    help charmap quickbars emoticons",

						imagetools_cors_hosts: ["picsum.photos"],
						menubar: "file edit view insert format tools table help",
						toolbar:
							"forms | tables | sections | company | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl ",
						toolbar_sticky: false,

						image_advtab: true,

						importcss_append: true,
						file_picker_callback: function (cb, value, meta) {
							var input = document.createElement("input");
							input.setAttribute("type", "file");
							input.setAttribute("accept", "image/*");

							/*
                Note: In modern browsers input[type="file"] is functional without
                even adding it to the DOM, but that might not be the case in some older
                or quirky browsers like IE, so you might want to add it to the DOM
                just in case, and visually hide it. And do not forget do remove it
                once you do not need it anymore.
              */

							input.onchange = function () {
								console.log("ajksdhakjsdhasjkd");
								var file = this.files[0];

								var reader = new FileReader();
								reader.onload = function () {
									/*
                    Note: Now we need to register the blob in TinyMCEs image blob
                    registry. In the next release this part hopefully won't be
                    necessary, as we are looking to handle it internally.
                  */
									var id = "blobid" + new Date().getTime();
									var blobCache = editorRef.current.editorUpload.blobCache;
									console.log("ajksdhakjsdhasjkd", editorRef.current);
									var base64 = reader.result.split(",")[1];
									var blobInfo = blobCache.create(id, file, base64);
									blobCache.add(blobInfo);

									/* call the callback and populate the Title field with the file name */
									cb(blobInfo.blobUri(), { title: file.name });
								};
								reader.readAsDataURL(file);
							};

							input.click();
						},
						templates: [
							{
								title: "New Table",
								description: "creates a new table",
								content:
									'<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
							},
							{
								title: "Starting my story",
								description: "A cure for writers block",
								content: "Once upon a time...",
							},
							{
								title: "New list with dates",
								description: "New List with dates",
								content:
									'<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
							},
						],
						template_cdate_format:
							"[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
						template_mdate_format:
							"[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
						height: window.screen.height - 400,
						image_caption: true,
						quickbars_selection_toolbar:
							"bold italic | quicklink h2 h3 blockquote quickimage quicktable",
						noneditable_noneditable_class: "mceNonEditable",
						toolbar_mode: "sliding",
						contextmenu: "link image imagetools table",
						// skin: useDarkMode ? "oxide-dark" : "oxide",
						// content_css: useDarkMode ? "dark" : "default",
						content_style:
							"body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
						setup: function (editor) {
              /* example, adding a toolbar menu button */
              if (forms) {
                editor.ui.registry.addMenuButton("forms", {
                  text: "My Forms",
                  fetch: (callback) => formMenu(callback, editor),
                });
              }

              if (tables)
                editor.ui.registry.addMenuButton("tables", {
                  text: "My Tables",
                  fetch: (callback) => tableMenu(callback, editor),
                });
              if (sections)
                editor.ui.registry.addMenuButton("sections", {
                  text: "My Sections",
                  fetch: (callback) => sectionMenu(callback, editor),
                });

              editor.ui.registry.addMenuButton("company", {
                text: "My Company",
                fetch: (callback) => companyMenu(callback, editor),
              });

            },
						init_instance_callback: function (editor) {
							editor.on("Click", function (e) {
								if (onClickEvent) onClickEvent(e);
							});
						},
						

					}}
					// onBlur={handleSubmit}
					// ref={editorRef}
					// nodeChange ={(editor_id, node, undo_index, undo_levels, visual_aid, any_selection)=>{
					// 	console.log("kjalshdkasjhdkjasdh",node);
					// }}
				/>
				<Box display="flex" justifyContent="center" mt={2} mb={2}>
					<ButtonWithLoading
						title={getTranslation("Save", "Save", "Save")}
						size="small"
						variant="contained"
						color="primary"
						loading={isSubmitting}
						onClick={handleSubmit}
					/>
				</Box>
			</>
		) : (
			<CircularProgress />
		);
	}
);
